import React from 'react'

function Radios(props) {
    const { items, selected, setSelected, className } = props

    const checkSelected = (val) => {
        if (typeof val === "boolean") {
            return !!selected === !!val
        }
        return selected === val 
    }
 
    return (
        <div className={ className }>
            {
                items.length ? items.map(item => (
                    <div className="d-flex align-items-center radio-item mr-3" onClick={() => setSelected(item.value)}>
                        <div className={ `radio-button ${ checkSelected(item.value) ? "filled" : "hollow"} my-1 mx-2 d-flex align-items-center justify-content-center` } data-testid={ `${item.value}-radio` }>
                            <div className={ `${ checkSelected(item.value) && "radio-fill"}` }></div>
                        </div>
                        <span>{ item.text }</span>
                    </div>
                )) : (
                    <span>No items available.</span>
                )
            }            
        </div>
    )
}

export default Radios
