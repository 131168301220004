
const getDates = (delta) => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const dateEnd = date.toISOString().split("T")[0]
    date.setDate(date.getDate() - delta + 1);
    const dateStart = date.toISOString().split("T")[0];
    return { "date-start": dateStart, "date-end": dateEnd };
};

export default getDates;