import { languageMappings } from "constants/mappings"

const mapLanguage = language => {

    language = language.toLowerCase()
    return Object.keys(languageMappings).includes(language)
            ? languageMappings[language]
            : Object.values(languageMappings).includes(language)
                ? Object.entries(languageMappings).filter(([k, v]) => v.toLowerCase() === language)[0][0]
                : ""
}

export { mapLanguage }