import React, { useEffect, useState } from 'react'
import Check from 'assets/icons/misc/Check.png'
import Blank from 'assets/icons/misc/Blank.png'
import { isArray } from 'underscore'


function TableCheckBox(props) {
    const { rowId, setSelectedItems, selectedItems, setSelectAll } = props
    const checked = selectedItems && selectedItems.includes(rowId)


    return (
        <>
            <img src={ checked ? Check : Blank } 
                alt="selected" width="15" 
                className="table-checkbox" 
                onClick={() => {
                    setSelectAll(null)
                    isArray(selectedItems) && setSelectedItems(checked ? selectedItems.filter(item => item !== rowId): [rowId, ...selectedItems]) }
                }
            />
        </>
    )
}

export default TableCheckBox
