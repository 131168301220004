import React, { useState } from 'react'
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { CheckBoxes, Helper } from 'components';
import { pathFor } from 'constants/apiPaths';
import dashboardPages from 'constants/dashboardPages'
import Request from 'services/Request';


function AddNewRole(props) {
    const { setMessageQueue } = props
    const history = useHistory()
    const [ roleName, setRoleName ] = useState("")
    const [ roleNameError, setRoleNameError ] = useState(null)
    const checkBoxes = []
    
    dashboardPages.forEach(item => {
        if (item.subItems) {
            checkBoxes.push({text: item.text, hasSubmenu: true})
            item.subItems.forEach(subItem => {
                checkBoxes.push({text: subItem.text, parent: item.text})
            })
        } else {
            checkBoxes.push({text: item.text})
        }
    })
    
    const [ permissions, setPermissions ] = useState(Object.fromEntries(checkBoxes.map(box => ([box.text, false]))))
    const checkBoxesProps = { permissions, setPermissions, checkBoxes }

    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessageQueue([])
        let noErrors = true

        if (roleName.length < 3 || roleName.length > 40) { noErrors = false; setRoleNameError("Length must be between 3 and 40"); }

        if (noErrors) {
            try {
                const { statusCode, jsonResponse } = await Request(pathFor.addRole, { role_name: roleName, 'components_access': Object.keys(permissions).filter(k => permissions[k]) })
                setMessageQueue("errors" in jsonResponse && jsonResponse.errors.json.role_name.length ? [{"error": jsonResponse.errors.json.role_name[0]}] : [jsonResponse])
                if (statusCode === 201) history.push("/dashboard/operations/roles")
                else setMessageQueue([jsonResponse])
            } catch {
                setMessageQueue([{error: "Server error. Try again after sometime."}])
            }
        }
    }
    
    return (
        <>
            <div className="crumb bg--secondary w-100 py-2 px-3 rounded">
                <span className="mr-2">Roles</span> 
                <p className="d-inline">
                    <FontAwesomeIcon icon={ faCaretRight } className="caret-right" />
                </p>
                <strong className="ml-2">Add new role</strong>
            </div>
            <p className="operation-table my-3">ADD NEW ROLE</p>
            <form className="col-xl-4 col-lg-5 col-md-6 col-sm-7 col-8 py-1 px-0" onSubmit={ handleSubmit }>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex"> 
                            <label htmlFor="role-name">
                                <span className="text--secondary">
                                    Role name
                                </span>
                            </label>
                            <Helper itemKey="name" variant="gray" />
                        </div>
                        <span className="text-danger">
                            <i>{ roleNameError }</i>
                        </span>
                    </div>
                    <input type="text" className="form-control" id="role-name" placeholder="Enter role name" onChange={e => { setRoleNameError(null); setRoleName(e.target.value.trim()); }} />
                </div>
                <span className="text--secondary">
                    <label>
                        Components access
                    </label>
                </span>
                <div className="mt-2 ml-1">
                    <CheckBoxes { ...checkBoxesProps } />
                </div>
                <div className="d-flex justify-content-end mt-4">
                    <button type="button" className="btn bg-white px-3 py-2 btn-cancel text--primary" onClick={() => history.push("/dashboard/operations/roles")}>Cancel</button>
                    <button type="submit" className="btn bg--primary text--white py-2 px-3 ml-3" disabled={ !(roleName.length && Object.keys(permissions).filter(k => permissions[k]).length)}>Create role</button>
                </div>
            </form>
        </>
    )
}

export default AddNewRole
