import React, { useEffect, useState } from 'react'
import Sort from './assets/Sort.svg'
import SortAsc from './assets/SortAsc.svg'
import SortDesc from './assets/SortDesc.svg'
import Check from 'assets/icons/misc/Check.png'
import Blank from 'assets/icons/misc/Blank.png'

function TableHead(props) {
    const { tableHead, setSortProps, setSelectAll, selectAll, headerCheckboxActive } = props
    const [ clickedOn, setClickedOn ] = useState(null)
    const [ ascending, setAscending ] = useState(false)
    const [ sortIcon, setSortIcon ] = useState(Sort)

    const handleClick = key => {
        setAscending(key === clickedOn ? !ascending : true)
        setClickedOn(key)
    }

    useEffect(() => {
        setSortProps && setSortProps({key: clickedOn, order: ascending ? "asc" : "desc"})
        setSortIcon(ascending ? SortAsc : SortDesc)
        // eslint-disable-next-line
    }, [ clickedOn, ascending ])

    return (
        <>
            {
                tableHead.filter(i => !i.hidden).map(tHead => (
                    <>
                        { tHead.sortable ? (
                            <th key={ tHead.key } className="clickable" onClick={() => handleClick(tHead.key) }>
                                { tHead.title }
                                <img src={ clickedOn === tHead.key ? sortIcon : Sort } alt="sort-icons" className="sort-icon" />
                            </th>
                        ) : (
                            <th key={ tHead.key }>
                                { tHead.checkBox && (
                                    <img src={ headerCheckboxActive ? Check : Blank } 
                                        alt="selected" width="15" 
                                        className="table-checkbox" 
                                        onClick={() => {
                                            setSelectAll(!selectAll)
                                        }}
                                    />
                                )}
                                { tHead.title }
                            </th>
                        )}
                    </>
                ))
            }
        </>
    )
}

export default TableHead
