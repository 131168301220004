import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Radios, Helper }  from 'components';
import { pathFor } from 'constants/apiPaths';
import { validateEmail } from 'utils/validations'
import Request from 'services/Request';


function AddNewAdmin(props) {
    const { setMessageQueue } = props
    const history = useHistory()
    const [ roles, setRoles ] = useState([])
    const [ name, setName ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ role, setRole ] = useState("")
    const [ nameError, setNameError ] = useState(null)
    const [ emailError, setEmailError ] = useState(null)

    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessageQueue([])
        let noErrors = true

        if (!validateEmail(email)) { noErrors = false; setEmailError("Invalid email."); }
        if (name.length < 3 || name.length > 40) { noErrors = false; setNameError("Length must be between 3 and 40"); }
        
        if (noErrors) {
            try {
                const { statusCode, jsonResponse } = await Request(pathFor.addAdmin, { name, email, role })
                if (statusCode === 201) history.push("/dashboard/operations/admin-user-management")
                else if (statusCode == 400) setMessageQueue([{error: Object.entries(Object.entries(jsonResponse.errors)[0][1])[0][1][0]}])
                else setMessageQueue([jsonResponse])
            } catch {
                setMessageQueue([{error: "Server error. Try again after sometime."}])
            }
        }
    }

    // eslint-disable-next-line
    useEffect(async () => {
        const { statusCode, jsonResponse } = await Request(pathFor.roles)
        if (statusCode === 200) {
            const { data } = jsonResponse
            let roleNames = []
            data.forEach(r => roleNames.push({text: r['role_name'], value: r['role_name']}))
            roleNames.length && setRoles(roleNames)
        } else {
            setMessageQueue([jsonResponse])
        }
    // eslint-disable-next-line
    }, [])


    return (
        <>
            <div className="crumb bg--secondary w-100 py-2 px-3 rounded">
                <span className="mr-2">Admin user management</span> 
                <p className="d-inline">
                    <FontAwesomeIcon icon={ faCaretRight } className="caret-right" />
                </p>
                <strong className="ml-2">Add new admin</strong>
            </div>
            <p className="operation-table my-3">ADD NEW ADMIN</p>
            <form className="col-xl-4 col-lg-5 col-md-6 col-sm-7 col-8 py-1 px-0" onSubmit={ handleSubmit }>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex"> 
                            <label htmlFor="admin-name">
                                <span className="text--secondary">
                                        Name
                                </span>
                            </label>
                            <Helper itemKey="name" variant="gray" />
                        </div>
                        <span className="text-danger">
                            <i>{ nameError }</i>
                        </span>
                    </div>
                    <input type="text" className="form-control" id="admin-name" placeholder="Enter the name" onChange={e => { setNameError(null); setName(e.target.value.trim()); }} />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="admin-email">
                            <span className="text--secondary">
                                Email
                            </span>
                        </label>
                        <span className="text-danger">
                            <i>{ emailError }</i>
                        </span>
                    </div>
                    <input type="email" className="form-control" id="admin-email" placeholder="Enter the email" onChange={e => { setEmailError(null); setEmail(e.target.value.trim()); }} />
                </div>
                <span className="text--secondary">
                    <label>
                        Role
                    </label>
                </span>
                <div className="mt-2 ml-1">
                    <Radios items={ roles } selected={ role } setSelected={ setRole } />
                </div>
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn bg-white px-3 py-2 btn-cancel text--primary" onClick={() => history.push("/dashboard/operations/admin-user-management")}>Cancel</button>
                    <button type="submit" className="btn bg--primary text--white py-2 px-3 ml-3" disabled={ !(name.length && email.length && role.length)}>Send invitation link</button>
                </div>
            </form>
        </>
    )
}

export default AddNewAdmin
