import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Request from 'services/Request'
import { validateEmail } from 'utils/validations'


function ResetPassword(props) {
    const { setMessageQueue } = props
    const history = useHistory()
    const [ email, setEmail ] = useState("")
    const [ submitted, setSubmitted ] = useState(false)
    const btnText = submitted ? "Close this window" : "Send reset link to email"

    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessageQueue([])

        if (!validateEmail(email)) {
            setMessageQueue([{'error': "Invalid email"}])
            return false
        }

        try {
            const { statusCode, jsonResponse } = await Request("/reset-password", { email })
            if (statusCode === 200) setSubmitted(true)
            setMessageQueue([jsonResponse])
        } catch (error){
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
    }

    return (
        <div>
            <div className="bg--secondary p-3 mt-3 rounded">
                <span className="pb-2">
                    <strong>
                        Steps to reset password      
                    </strong>
                </span>
                <span className="hints">1. Enter your registered email address</span>
                <span className="hints">2. Follow the link received in the mail, to setup new password</span>
            </div>
            <form className="mt-4" onSubmit={ handleSubmit }>
                <div className="form-group">
                    <label htmlFor="email" className="label d-flex justify-content-between">
                        <strong>Email</strong>
                    </label>
                    <input 
                        type="email" 
                        className="form-control" 
                        id="email" 
                        placeholder="Enter email"
                        onChange={e => setEmail(e.target.value)}
                        disabled={ submitted }
                    />
                </div>
                <div className="d-flex justify-content-center mt-4 py-2">
                    <button 
                        type="submit" 
                        className="btn btn-primary py-2 px-3" 
                        disabled={ !email.trim() } 
                        onClick={() => submitted && history.push("/accounts/login")}
                    >
                        { btnText }
                    </button>
                </div>
                <a href="/accounts/login" className={ `d-${ submitted ? "none" : "block"} text-center` }>Click here to Login</a>
            </form>
        </div>
    )
}

export default ResetPassword
