const capitalise = val => val.charAt(0).toUpperCase() + val.slice(1)

const compareArrays = (arr1, arr2) => {
    
    if (arr1.length !== arr2.length) return false

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false
    }

    return true
}

const pluralise = (text, dependent_on, is_verb) => {
   
    let suffix = ""
    if ((dependent_on === 1 && is_verb) || (dependent_on !== 1 && !is_verb)) suffix = "s"
    return text + suffix
}

export { capitalise, compareArrays, pluralise }