import { pathFor } from 'constants/apiPaths'
import Request from './Request'

const InsightsAPI = async (payload) => {

    const { statusCode, jsonResponse } = await Request(pathFor.insights, payload)
    const data = {}
    if (statusCode === 200) {
        const { fast, medium, slow, non_compliance_reasons_count, insights_table } = jsonResponse
        data['insightsTable'] = insights_table || []
        data['pageSpeeds'] = fast && medium && slow ? [
            {
                label: "< 500ms",
                value: fast
            }, {
                label: ">= 500ms < 1500ms",
                value: medium
            }, {
                label: ">= 1500ms",
                value: slow
            }
        ] : []
        data['nonComplianceReasons'] = ![null, undefined].includes(non_compliance_reasons_count) ? Object.entries(non_compliance_reasons_count).map(([k, v]) => ({
            label: k.split("/")[0], value: v
        })) : []
    }
    return data
}

export default InsightsAPI