import React, { useState } from 'react'
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import PdfCreator from './PdfCreator';

function ExportButton(props) {
    const { exportData, pdf } = props
    const [ showMenu, setShowMenu ] = useState(false)

    return (
        <div className="d-flex flex-column align-items-end" onClick={() => setShowMenu(!showMenu)}>
            <button data-testid="export-button" className="btn btn-white mt-1 pt-2 drop-down d-flex justify-content-between">
                Export as
                <FontAwesomeIcon icon={ faCaretDown } className="m-1" />
            </button>
            <div className={ `d-${ showMenu ? "flex" : "none"} flex-column selector-menu drop-down-menu py-1` }>
            {
                exportData && (
                    <CSVLink
                        data={ exportData }
                        filename={ `${(new Date()).toISOString()}.csv` }
                        className="drop-down-item"
                        target="_blank"
                    >
                        CSV
                    </CSVLink>
                )
            }
            {
                pdf && (
                    <PdfCreator targetRef={ pdf } filename={ `${(new Date()).toISOString()}.pdf` } x={ 10 } y={ 15 } logoRequired={ true } >
                        {({ toPdf }) => <span onClick={ toPdf } className="drop-down-item clickable">PDF</span>}
                    </PdfCreator>
                )
            }
            </div>
        </div>
    )
}

export default ExportButton