const advertiserDashboardStats = [
	{
		text: "Total no. of Searches",
		key: "search_count",
		defaultValue: "0"
	},
	{
		text: "1st URL Relevancy Avg.",
		key: "top1_avg",
		helper: true,
		defaultValue: "0",
		isPercentage: true
	},
	{
		text: "Top 10 URL Relevancy Avg.",
		key: "top10_avg",
		helper: true,
		defaultValue: "0",
		isPercentage: true
	},
	{
		text: "Top 50 URL Relevancy Avg.",
		key: "top50_avg",
		helper: true,
		defaultValue: "0",
		isPercentage: true
	}
]

const ninaBotStats = [
	{
		text: "No. of URLs crawled",
		key: "page_count",
		defaultValue: "0"
	},
	{
		text: "No. of Visits",
		key: "visit_count",
		defaultValue: "0"
	},
	{
		text: "Average URLs crawled per domain",
		key: "urls_per_domain_mean",
		defaultValue: "0"
	}
]

const ninaBotPerformance = [
	{
		text: "Avg. Page load speed",
		key: "avg_page_load_speed",
		defaultValue: "0",
		unit: "ms"
	},
	{
		text: "Slow URLs (>= 1.5s)",
		defaultValue: "0",
		key: "slow"
	},
	{
		text: "Fast URLs (< 500ms)",
		defaultValue: "0",
		key: "fast"
	},
	{
		text: "Avg. Crawling frequency",
		key: "crawl_frequency",
		defaultValue: "0",
		unit: "pages/min",
	}
]


const datasetActivities = [
        {
                text: "Total used",
                key: "total_used",
                defaultValue: "0"
        },
        {
                text: "Downloaded",
                key: "downloaded",
                defaultValue: "0"
        },
        {
                text: "Pushed",
                key: "pushed",
                defaultValue: "0"
        },
        {
                text: "Push failed",
                key: "push_failed",
                defaultValue: "0"
        }
]

const banners = [
	{
		bannerKey: "datasetActivities",
		title: "URLS",
		metrics: datasetActivities
    }, {
		bannerKey: "advertiserDashboardStats",
		title: "ADVERTISER DASHBOARD STATS",
		variant: "primary",
		metrics: advertiserDashboardStats
	}, {
		bannerKey: "ninaBotStats",
		title: "NINA BOT STATS",
		metrics: ninaBotStats
	}, {
		bannerKey: "ninaBotPerformance",
		title: "NINA BOT PERFORMANCE",
		variant: "white",
		metrics: ninaBotPerformance
	}
]

export { banners };