import React, { useEffect, useState }  from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Helper from './Helper'
import DateSelector from './DateSelector';
import Request from 'services/Request'
import { pathFor } from 'constants/apiPaths';
import getDates from 'utils/getDates';


function Banner(props) {
    const { bannerKey, variant, title, metrics, setMessageQueue } = props;
    const textVariant = variant === "primary" && "white"
    const bannerClass = `bg--${ variant } text-${ textVariant } p-4`;
    const [ metricValues, setMetricValues ] = useState({})
    const [ dateDelta, setDateDelta ] = useState(7)

    // eslint-disable-next-line
    useEffect(async () => {
        setMetricValues({})
        const payload = getDates(dateDelta)
        try {
            const { statusCode, jsonResponse } = await Request(pathFor[bannerKey], payload)
            if (statusCode === 200) setMetricValues(jsonResponse)
            else if (statusCode === 401) setMessageQueue([jsonResponse])
        } catch(err) {
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
    }, [bannerKey, dateDelta])

    return (
        <div className={ bannerClass }>
            <div className="d-flex justify-content-between align-items-center">
                <p className={ `mt-3 text--${ textVariant || "secondary" }` }>{ title }</p>
                <DateSelector variant={ variant } setDateDelta={ setDateDelta } />
            </div>

            <Row className="mt-4">
                {
                    metrics.map((item, idx) => (
                        <Col className={ `${ idx && "border-left"}` } lg={3} key={ idx }>
                            <span className="metric-title d-flex">
                                { item.text }
                                { item.helper && (
                                    <span className="pt-1">
                                        <Helper itemKey={ item.key } variant="white"/> 
                                    </span>
                                )}
                            </span>
                            <span className="metric-value">
                                { !Object.entries(metricValues).length ? "--" : item.isPercentage ? `${(metricValues[item.key] || item.defaultValue) * 100}%` : metricValues[item.key] || item.defaultValue } 
                                { item.unit && metricValues && metricValues[item.key] ? <span className="unit">{ item.unit }</span> : undefined}
                            </span>
                        </Col>
                    ))
                }
            </Row>
        </div>
    )
}

export default Banner;
