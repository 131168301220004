import { DataTable, DateRangesPicker } from 'components'
import { pathFor } from 'constants/apiPaths'
import { usersActivitiesHead } from 'constants/tableConstants'
import React, { useEffect, useState } from 'react'
import Request from 'services/Request'
import { Spinner } from 'react-bootstrap'


function ClientReport(props) {
    const { currentState, setCurrentState, setMessageQueue } = props
    const [ dateRanges, setDateRanges ] = useState(currentState?.dateRanges)
    const [ filterValue, setFilterValue ] = useState(currentState?.filterValue)
    const [ reports, setReports ] = useState(currentState?.reports || [])
    const [ isClicked, setIsClicked ] = useState(currentState?.isClicked)

    const apiCall = async () => {
        setIsClicked(true)
		setReports([])
		try {
            const { statusCode, jsonResponse } = await Request(pathFor.usersActivities, { ...dateRanges, "filter-key": filterValue })
            if (statusCode === 200) {
				const { data } = jsonResponse
				const activities = []
				data.forEach(task => {
					const userEmail = task.initiated_by.user_email || ""
					const organisation = task.initiated_by.organisation_name || ""
					delete task["initiated_by"]
					delete task["queued_on"]
					const status = task.status || "queued"
					const created_by = `${organisation} :: ${userEmail}`
					task = JSON.stringify(task)
					activities.push({task, status, created_by})
				})
                setReports(activities)
                setCurrentState({
                    dateRanges,
                    isClicked: true,
                    reports: activities,
                    filterValue
                })
            }
        } catch (err) {
            setMessageQueue([{error: "Server error. Try again after sometime."}])
            setIsClicked(false)
        }
    }

    return (
        <div>
            <div className="mb-3 ">
                <span className="text-muted d-block mb-3">Date Range</span>
                <div className="d-flex">
                    <span className="mr-2 col-3 p-0 border--light rounded">
                        <DateRangesPicker setPayload={ setDateRanges } currentValue={ dateRanges }/>
                    </span>
                    <input placeholder="Search" name="filterValue" className="border--light w-25 rounded py-2 pl-3 mr-2" onChange={e => setFilterValue(e.target.value)} value={ filterValue } />
                    <button
                        type="submit"
                        className={ `btn px-3 btn-${ !!dateRanges  ? "primary": "disabled"} border-0` } 
                        onClick={ apiCall }
                        disabled={ !dateRanges }
                    >
                        <span>View report</span>
                    </button>
                </div>
            </div>
            {
                !isClicked 
                    ? <></>
                    : reports
                        ? <DataTable tableData={ { tableHead: usersActivitiesHead, tableBody: reports } } infoHead={ true } setMessageQueue={ setMessageQueue } />
                        : (
                            <div className="d-flex justify-content-center mt-5">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        ) 
            }          
        </div>
    )
}

export default ClientReport