import React from 'react'
import CollapseIcon from './assets/CollapseIcon.svg';

function CollapseButton(props) {
    const { collapseButtonVisibility, collapse, setCollapse } = props
    
    return (
        <>
            {
                collapseButtonVisibility && (
                    <img src={ CollapseIcon } 
                        className={ `collapse-button ${ collapse && "rotate-button" }`} 
                        onClick={() => collapse ? setCollapse(false) : setCollapse(true) }  
                    />
                )
            }
        </>
    )
}

export default CollapseButton
