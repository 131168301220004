import React, { useEffect } from 'react'
import Sidebar from 'components/Sidebar'
import Routes from './Routes'
import logo from 'assets/images/logo/Logo.svg'
import logoText from 'assets/images/logo/LogoText.svg'
import Request from 'services/Request'
import { generateAccessiblePages } from 'utils/accesiblePages'

function Page() {
    const pages = generateAccessiblePages()
    const sidebarProps = { pages, logos: {logo, logoText} }
    
    useEffect(async () => {
        try {
            const { statusCode } = await Request("/verify-token")
            if (statusCode === 201) window.location.reload()
        } catch(err) {
            console.log(err)
        }
    }, [])

    return (
        <div className="App d-flex">
            <Sidebar { ...sidebarProps }/>
            <div className="flex-grow-1">
                <Routes pages={ pages }/>
            </div>
		</div>
    )
}

export default Page
