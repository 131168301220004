import React, { useEffect, useState } from 'react'
import TableHead from './TableHead'
import TableBody from './TableBody'


function Table(props) {
    const { tableData, className } = props
    const [ headerCheckboxActive, setHeaderCheckboxActive ] = useState(null)
    const [ selectAll, setSelectAll ] = useState(null) 

    props = { selectAll, setSelectAll, headerCheckboxActive, setHeaderCheckboxActive, ...props}

    return (
        <table className={ className }>
            <thead>
                <tr className="text-muted">
                    <TableHead tableHead={ tableData.tableHead } { ...props } />
                </tr>
            </thead>
            <tbody>
                <TableBody { ...props } /> 
            </tbody>
        </table>
    )
}
          
export default Table
