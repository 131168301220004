import React, { useEffect, useState } from "react";
import Banner from "components/Banner";
import { banners } from "constants/bannerConstants";
import Insights from "./Insights";
import Request from "services/Request";
import { pathFor } from "constants/apiPaths";


function Overview(props) {
	const { setMessageQueue } = props
	const [ userSections, setUserSections ] = useState([])

	useEffect(async () => {
		try {
			const { statusCode, jsonResponse } = await Request(pathFor.usersStats)
			if (statusCode === 200) setUserSections(jsonResponse.data)
		} catch(err) {
			setMessageQueue([{error: "Server error. Try again after sometime."}])
		}
	}, [])

	return (
		<div className="bg--light">
			<div className="bg-white px-3">
                <div className="mx-2 text--secondary d-flex justify-content-between">
					<p>USERS</p>
					<h6 className="mr-5 font-weight-bold">
						Total :&nbsp; 
						<h5 className="d-inline text-dark">
							{ userSections.reduce((acc, val) => acc + val.total, 0) }
						</h5>
					</h6>
				</div>
				<div className="row p-2">
					{
						userSections.map((section, idx) => (
							<div className={ `${ idx && "border-left"} col py-2 mb-3` }>
								<span className="font-weight-bold">No. of { section.title[0].toUpperCase() + section.title.slice(1) } : { section.total }</span>
								<div className="row pt-4">
									{
										section.counts && Object.entries(section.counts).map(([k, v], idx) => (
											<div className={ `${ idx && "border-left"} col` } >
												<span>{ k[0].toUpperCase() + k.slice(1) }</span>
												<h3 className="pt-3">{ v }</h3>
											</div>
										))
									}
								</div>
							</div>
						))
					}
					</div>
			</div>
			{
				banners.map((banner, idx) => (
					<Banner key={ `${idx}` } { ...banner } setMessageQueue={ setMessageQueue } />
				))
			}
			<Insights setMessageQueue={ setMessageQueue } />
		</div>
	);
}

export default Overview;
