import SetPassword from "../SetPassword"
import Login from "../Login";
import ResetPassword from "../ResetPassword";

const pageRoutes = [
    {
        path: "/login",
        component: Login
    },
    {
        path: "/set-password",
        component: SetPassword
    },
    {
        path: "/reset-password",
        component: ResetPassword
    },
    {
        path: "/change-password",
        component: SetPassword
    }
]

export default pageRoutes;