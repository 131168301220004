import React from 'react'

function LogoSection(props) {
    const { logos, collapse } = props
    const { logo, logoText } = logos 

    return (
        <div className="logo-zone bg--secondary d-flex align-items-center">
            <a href="/"> 
                <img src={ logo } alt="logo" className={ `ml-${ collapse ? '2' : '4' }` } height="32" width="32"/>
                <img src={ logoText } alt="logo" className={ `ml-2 mt-1 ${ collapse && "d-none" }` }/>
            </a>
        </div>
    )
}

export default LogoSection
