import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Request from 'services/Request'
import validatePassword from './utils/validatePassword'

function SetPassword(props) {
    const { setMessageQueue } = props
    const history = useHistory()
    const [ password, setPassword ] = useState("")
    const [ confirmPassword, setConfirmPassword] = useState("")
    const [ passwordError, setPasswordError ] = useState("")
    const [ confirmPasswordError, setConfirmPasswordError ] = useState("")
    const [ inputLabel, btnText ] = history && history.location.pathname === "/accounts/set-password" ? [ "Set password", "Activate account" ] : [ "New password", "Change password" ]
    const [ inputDisabled, setInputDisabled ] = useState(false)
    const token = history ? history.location.search.split("token=")[1] : null

    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessageQueue([])
        const passwordValidation = validatePassword(password)
        setPasswordError(passwordValidation)
        setConfirmPasswordError(password !== confirmPassword && "Passwords do not match")
       
        if (password === confirmPassword && !passwordValidation) {
            try {
                const headers = { 'Authorization': `Bearer ${token}`}
                const { statusCode, jsonResponse } = await Request("/set-password", { password }, headers)
                if (statusCode === 200) {
                    setMessageQueue([jsonResponse, {'success': "Redirecting..."}])
                    setInputDisabled(true)
                    setTimeout(() => history.push("/login"), 2000)
                } else setMessageQueue([{error: "Unable to set password."}])
            } catch {
                setMessageQueue([{error: "Server error. Try again after sometime."}])
            }
        }
    }

    useEffect(() => {
        setPasswordError("")
        setConfirmPasswordError("")
    }, [password, confirmPassword])

    return (
         <div>
            <div className="bg--secondary p-3 mt-3 rounded">
                <span className="pb-2">
                    <strong>
                        Password policy        
                    </strong>
                </span>
                <span className="hints">Minimum 8 characters</span>
                <span className="hints">Should contain both upper and lower case letters</span>
            </div>
            <form className="mt-4" onSubmit={ handleSubmit }>
                <div className="form-group">
                    <label htmlFor="set-password" className="label d-flex justify-content-between">
                        <strong>{ inputLabel }</strong>
                        <span className="text-danger" data-testid="new-password-error">
                            <i>{ passwordError }</i>
                        </span>
                    </label>
                    <input 
                        type="password" 
                        className="form-control" 
                        id="set-password" 
                        placeholder="Enter password"
                        onChange={e =>  setPassword(e.target.value)}
                        disabled={ inputDisabled }
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="confirm-password" className="label d-flex justify-content-between">
                        <strong>Confirm password</strong>
                        <span className="text-danger" data-testid="confirm-password-error">
                            <i>{ confirmPasswordError }</i>
                        </span>    
                    </label>                        
                    <input
                        type="password" 
                        className="form-control" 
                        id="confirm-password" 
                        placeholder="Re-enter password to confirm"    
                        onChange={e => setConfirmPassword(e.target.value)} 
                        disabled={ inputDisabled }
                    />
                </div>
                <div className="d-flex justify-content-center mt-4 pt-2">
                    <button type="submit" className="btn btn-primary py-2 px-3" disabled={ !password.trim() || !confirmPassword.trim() || inputDisabled }>{ btnText }</button>
                </div>
            </form>
        </div>
    )
}

export default SetPassword
