import { useEffect, useState } from 'react';
import JsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import Logo from 'assets/images/logo/Logo.svg'
import LogoText from 'assets/images/logo/LogoText.svg'


function PdfCreator(props) {
    const { targetRef, filename, x, y, options, scale, onComplete, logoRequired } = props
    const [ imageDataURL, setImageDataURL ] = useState(null)
    const [ dimensions, setDimensions ] = useState({x, y})

    const createLogoImage = () => {
        const logo = new Image()
        const logoText = new Image()
        logo.onload = () => {
            const canvas = document.createElement("canvas")
            canvas.width = 280
            canvas.height = 150
            const ctx = canvas.getContext("2d")
            ctx.drawImage(logo, 0, 0, 100, 100)
            ctx.drawImage(logoText, 110, 25, 160, 40)
            const dataURL = canvas.toDataURL()
            setImageDataURL(dataURL)
        }
        logo.src = Logo
        logoText.src = LogoText
    }

    const toPdf = () => {
        const targetComponent = targetRef.current;
        
        html2canvas(targetComponent, {
            scale: scale || 1
        }).then(canvas => {
            const imgData = canvas.toDataURL();
            const pdf = new JsPdf(options);
            imageDataURL && pdf.addImage(imageDataURL, 'JPEG', 70, 20)
            pdf.addImage(imgData, 'JPEG', dimensions.x, dimensions.y);
            pdf.save(filename);
            if (onComplete) onComplete();
        });
    }

    useEffect(() => {
        if (logoRequired) {
            createLogoImage()
            setDimensions({x, y: y + 50})
        }
    }, [logoRequired])


    return (
        props.children({ toPdf, targetRef })
    )
}

export default PdfCreator
