import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { DataTable } from 'components'
import { pathFor } from 'constants/apiPaths';
import { domainsTableHead } from 'constants/tableConstants'
import Request from 'services/Request'
import { ReactComponent as WhiteKebabIcon } from 'assets/icons/misc/WhiteKebabIcon.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/misc/SearchIcon.svg'
import { isArray } from 'underscore'
import Download from 'assets/icons/misc/download.png';
import { CSVLink } from 'react-csv';


function InventoryDomains(props) {
    const { setMessageQueue } = props
    const history = useHistory()
    const [ tableBody, setTableBody ] = useState(null)
    const [ optionsVisibility, setOptionsVisibility ] = useState(false)
    const [ filterValue, setFilterValue ] = useState("")
    const [ parentProp, setParentProp ] = useState(null)
    const [ reload, setReload ] = useState(null)
    const [ selectedItems, setSelectedItems ] = useState([])
    const pageLengthOptions = [10, 25, 50]
    const selectionLimit = 50
    const selectedItemsLength = selectedItems.length
    
    const datatableProps = {
        setMessageQueue,
        pageLengthOptions,
        setParentProp,
        tableData: { tableHead: domainsTableHead, tableBody },
        selectionLimit,
        selectedItems,
        setSelectedItems,
        selectedItemsLength
    }

    const filterDomains = async (event) => {
        event.preventDefault();
        const value = event.target.filterValue.value.trim()

        if (!value.length) {
            return setReload(true)
        }
        if (value === filterValue) return

        const { statusCode, jsonResponse } = await Request(pathFor.filterDomains, {filter_key: value})
        if (statusCode === 200) {
            const { data } = jsonResponse
            setTableBody(data)
        } else setMessageQueue([jsonResponse])

        setFilterValue(value)
    }

    // eslint-disable-next-line
    useEffect(async () => {
        if (reload === false) return
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.inventoryDomains)
            if (statusCode === 200) {
                const { data } = jsonResponse
                setTableBody(data)
                setReload(false)
            } else setMessageQueue([jsonResponse])
        } catch {
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
        setReload(false)
    }, [reload])


    const handleSelectedValues = async () => {
        setMessageQueue([])
        if (isArray(selectedItems) && selectedItemsLength) {
            if (selectedItemsLength > selectionLimit) {
                setMessageQueue([{"error": `Rerun failed. More than ${selectionLimit} domains selected.`}])
                return
            }
            const { statusCode, jsonResponse } = await Request(pathFor.reCrawl, {domain_ids: selectedItems})
            statusCode === 200 && setMessageQueue([jsonResponse])
            setReload(true)
        } else {
            setMessageQueue([{"error": "No domains selected to recrawl."}])
        }
    }

    useEffect(() => {
        setMessageQueue([])
        const infoComponent = selectedItemsLength ? (<h3 className={ `text--${selectedItemsLength > selectionLimit ? "red" : "primary" } ml-2 my-auto` }>Selected: {selectedItemsLength}</h3>) : (<></>)
        setParentProp && setParentProp(infoComponent)
    }, [selectedItems])


    return (
        <div className="col-11 p-0">
            <div className="d-flex justify-content-between align-items-top mb-2">
                <span className="d-flex">
                    <button type="button" className="btn bg--primary px-1 ml-3" onClick={() => setOptionsVisibility(!optionsVisibility)}><WhiteKebabIcon /></button>
                    {
                        optionsVisibility && (
                            <div className="border--light py-1 bg-white rounded my-5 ml-2 position-absolute kebab-options">
                                <div className="bg--secondary pl-2 pr-5" onClick={() => {
                                    handleSelectedValues();
                                    setOptionsVisibility(false);
                                }}>Rerun</div>
                            </div>
                        )
                    }
                    {
                        parentProp
                    }
                </span>
                <span className="d-flex">
                    {
                        tableBody && <CSVLink
                        headers={ domainsTableHead.map(({ title, key }) => ({ label: title, key })) }
                        data={ tableBody }
                        data-testid="export-dropdown"
                        filename={ `inventory_domains_${(new Date()).toISOString()}.csv` }
                            className="px-3 py-2 rounded border--light mr-3"
                            id="exportButton"
                            target="_blank"
                            >
                            Download 
                            <img src={ Download } alt="download" height="14" className=" pl-4" />
                        </CSVLink>
                    }
                    <form className="search-filter border--light rounded d-flex" onSubmit={ filterDomains }>
                        <input placeholder="Search" name="filterValue" className="border-0 outline-none rounded py-2 pl-3 flex-grow-1"/>
                        <button type="submit" className="border-0 bg--white p-0">
                            <SearchIcon className="my-2 mr-2 ml-1" />
                        </button>
                    </form>
                    <button type="button" className="btn bg--primary text--white px-3 py-2 ml-3" onClick={() => history.push(history.location.pathname + "/add")}>Add new domains</button>
                </span>
            </div>
            <DataTable { ...datatableProps } />
        </div>
    )
}

export default InventoryDomains
