import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { DataTable } from 'components'
import { pathFor } from 'constants/apiPaths'
import { rolesTableHead } from 'constants/tableConstants'
import Request from 'services/Request'

function Roles(props) {
    const { setMessageQueue } = props
    const history = useHistory()
    const [ tableBody, setTableBody ] = useState(null)
    
    // eslint-disable-next-line
    useEffect(async () => {
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.roles)
            if (statusCode === 200) {
                const { data } = jsonResponse
                setTableBody(data)
            } else setMessageQueue([jsonResponse])
        } catch {
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
        // eslint-disable-next-line
    }, [])
    
    return (
        <div className="col-xl-5 col-lg-6 col-md-9 col-10 p-0">
            <div className="d-flex justify-content-between align-items-top mb-2">
                <p className="operation-table">CURRENT ROLES</p>
                <button type="button" className="btn bg--primary text--white px-3" onClick={() => history.push(history.location.pathname + "/add")}>Add new role</button>
            </div>
            <DataTable tableData={ { tableHead: rolesTableHead, tableBody } } setMessageQueue={ setMessageQueue } noPagination={ true }/>
        </div>
    )
}

export default Roles
