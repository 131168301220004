import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import Close from 'assets/icons/misc/Close.svg'


const msgTypes = {
    error: {
        icon: faTimesCircle,
        color: "red",
    },
    success: {
        icon: faCheckCircle,
        color: "green"
    },
    info: {
        icon: faInfoCircle,
        color: "blue"
    }
}


function Snackbar(props) {
    const { msg } = props
    const [ display, setDisplay ] = useState("flex")
    const msgType =  msgTypes[Object.keys(msg)[0]]
    const { color, icon } = msgType
    
    useEffect(() => {
        setTimeout(() => setDisplay("none"), 10000)
    }, [])

    return (
        <>
            <div className={ `snackbar-${color} rounded p-3 mt-3 d-${display} justify-content-between align-items-center` }>
                <span className="d-flex align-items-center">
                    <FontAwesomeIcon icon={ icon } className={ `mr-2 text--${color}` } />
                    <span className="px-1">{ Object.values(msg)[0] }</span>
                </span>
                <strong className="cross" onClick={() => setDisplay("none") }>
                    <img src={ Close } alt="close" />
                </strong>
            </div>
        </>
    )
}

export default Snackbar
