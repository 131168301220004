import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { Radios, Helper } from 'components';
import { pathFor } from 'constants/apiPaths';
import { validateEmail } from 'utils/validations';
import Request from 'services/Request';
import { capitalise } from 'utils/common'


function EditClient(props) {
    const { setMessageQueue } = props
    const history = useHistory()
    const id = history.location.pathname.split("/").pop()
    const [ readOnly, setReadOnly ] = useState(false)
    const [ name, setName ] = useState("")
    const [ email, setEmail ] = useState("")
    const [ companyName, setCompanyName ] = useState("")
    const [ userType, setUserType ] = useState("")
    const [ active, setActive ] = useState("")
    const [ junk, setJunk ] = useState("")
    const [ emailError, setEmailError ] = useState(null)
    const [ nameError, setNameError ] = useState(null)
    const [ companyNameError, setCompanyNameError ] = useState(null)

    const userTypeOptions = [{text: "Internal", value: 0}, {text: "Advertiser", value: 1}, {text: "Publisher", value: 2}] 
    const statuses = [{text: "Active", value: true}, {text: "Inactive", value: false}]

    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessageQueue([])
        let noErrors = true
       
        if (readOnly && junk) {
            setMessageQueue([{error: "The email cannot be changed."}])
            return false
        }

        if (!validateEmail(email)) { noErrors = false; setEmailError("Invalid email."); }
        if (name.length < 3 || name.length > 40) { noErrors = false; setNameError("Length must be between 3 and 40"); }
        if (companyName.length < 3 || companyName.length > 20) { noErrors = false; setCompanyNameError("Length must be between 3 and 20"); }

        if (noErrors) {
            try {
                const { statusCode, jsonResponse } = await Request(`${pathFor.editClient}/${id}`, { 
                    name, email, active, 'company_name': companyName,
                    'organisation_type': userTypeOptions.filter(o => o.value === userType)[0].text.toLowerCase()
                })
                if (statusCode === 200) history.push("/dashboard/operations/client-management")
                else if (statusCode == 400) setMessageQueue([{error: Object.entries(Object.entries(jsonResponse.errors)[0][1])[0][1][0]}])
                else setMessageQueue([jsonResponse])
            } catch {
                setMessageQueue([{error: "Server error. Try again after sometime."}])
            }
        }
    }

    // eslint-disable-next-line
    useEffect(async () => {
        try {
            const { statusCode, jsonResponse } = await Request(`/client/${id}`)
            if (statusCode === 200) {
                const data = jsonResponse.data[0]
                setName(data.name || "")
                setEmail(data.email || "")
                setReadOnly(![undefined, null].includes(data.active))
                setActive(data.active)
                setCompanyName(data['company_name'] || "")
                setUserType(userTypeOptions.filter(o => o.text === capitalise(data.organisation_type || ""))[0]?.value)
            } else setMessageQueue([jsonResponse])
        } catch(err) {
            setMessageQueue([{error: err.message + " client details."}])
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="crumb bg--secondary w-100 py-2 px-3 rounded">
                <span className="mr-2">Client management</span> 
                <p className="d-inline">
                    <FontAwesomeIcon icon={ faCaretRight } className="caret-right" />
                </p>
                <strong className="ml-2">Edit client</strong>
            </div>
            <p className="operation-table my-3">EDIT CLIENT</p>
            <form className="col-xl-4 col-lg-5 col-md-6 col-sm-7 col-8 py-1 px-0" onSubmit={ handleSubmit }>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex"> 
                            <label htmlFor="client-name">
                                <span className="text--secondary">
                                    Name
                                </span>
                            </label>
                            <Helper itemKey="name" variant="gray" />
                        </div>
                        <span className="text-danger">
                            <i>{ nameError }</i>
                        </span>
                    </div>
                    <input type="text" className="form-control" id="client-name" placeholder="Enter the name" onChange={e => { setNameError(null); setName(e.target.value.trim()); }} value={ name } />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="client-email">
                            <span className="text--secondary">
                                Email
                            </span>
                        </label>
                        <span className="text-danger">
                            <i>{ emailError }</i>
                        </span>
                    </div>
                    <input type="email" className="form-control" id="client-email" placeholder="Enter the email" readOnly={ readOnly } onChange={e => { setEmailError(null); readOnly ? setJunk(e.target.value.trim()) : setEmail(e.target.value.trim()); }} value={ email }/>
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex"> 
                            <label htmlFor="client-company-name">
                                <span className="text--secondary">
                                    Company name
                                </span>
                            </label>
                            <Helper itemKey="company_name" variant="gray" />
                        </div>
                        <span className="text-danger">
                            <i>{ companyNameError }</i>
                        </span>
                    </div>
                    <input type="text" className="form-control" id="client-company-name" placeholder="Enter the company name" onChange={e => { setCompanyNameError(null); setCompanyName(e.target.value.trim()); }} value={ companyName } />
                </div>
                {
                    readOnly ? (
                        <>
                            <span className="text--secondary">
                                <label>
                                    Status
                                </label>
                            </span>
                            <div className="mt-2 mb-3 ml-1">
                                <Radios items={ statuses } selected={ active } setSelected={ setActive } />
                            </div>
                        </>
                    ) : (
                        <>
                            <span className="text--secondary">
                                <label>
                                    User type
                                </label>
                            </span>
                            <div className="mt-2 mb-3 ml-1">
                                <Radios items={ userTypeOptions } selected={ userType } setSelected={ setUserType } />
                            </div>
                        </>
                    )
                }
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn bg-white px-3 py-2 btn-cancel text--primary" onClick={() => history.push("/dashboard/operations/client-management")}>Cancel</button>
                    <button type="submit" className="btn bg--primary text--white py-2 px-3 ml-3" disabled={ !(name.length && email.length && companyName.length)}>Make changes</button>
                </div>
            </form>
        </>
    )
}

export default EditClient
