import React, { useEffect, useState } from 'react'
import Request from 'services/Request'
import { pathFor } from 'constants/apiPaths'
import { DataTable, DateRangesPicker, DropDown } from 'components'
import { Spinner } from 'react-bootstrap'
import { customDatasetReportTableHead } from 'constants/tableConstants'


function CustomDatasetReport(props) {
    const { currentState, setCurrentState, setMessageQueue } = props
    const [ payload, setPayload ] = useState(currentState ? currentState.payload : null)
    const [ reports, setReports ] = useState(currentState ? currentState.reports : null)
    const [ isClicked, setIsClicked ] = useState(currentState ? currentState.isClicked : false)
    const [ customer_name, setCustomerName ] = useState(currentState ? currentState.customer_name : null)
    const [ customerList, setCustomerList ] = useState([])
    const disabled = !payload || !customer_name?.length


    const apiCall = async () => {
        setIsClicked(true)
        setMessageQueue([])

        try {
            const { statusCode, jsonResponse } = await Request(pathFor.customDatasetReport, { ...payload, customer_name})
            if (statusCode === 200) {
                const { data } = jsonResponse
                setReports(data)
                setCurrentState({
                    payload,
                    customer_name,
                    isClicked: true,
                    reports: data
                })
            } else if (statusCode === 401) {
                setIsClicked(false)
                setMessageQueue([jsonResponse])
            } else {
                setIsClicked(false)
                setMessageQueue([{"error": "Unable to fetch data."}])
            }
        } catch (err) {
            console.log(err)
            setIsClicked(false)
            setMessageQueue([{"error": "Server error. Try again after sometime."}])
        }
    }

    
    useEffect(async () => {
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.fetchCustomers)
            if (statusCode === 200) setCustomerList(jsonResponse.customer_names || [])
            else setMessageQueue([{error: "Failed to fetch customer suggestions."}])
        } catch (err) {
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
    }, [])
    
    
    return (
        <div>
            <div className="d-flex">
                <div className='w-25 mb-4'>
                    <span className="text-muted d-block mb-3">Customer name</span>
                    <DropDown 
                        btnClassName="w-100 border--light"
                        menuClassName="w-75 border--light mt-1"
                        title="Select customer name"
                        defaultValue={ customer_name }
                        options={ customerList }
                        selected={ customer_name }
                        setSelected={ setCustomerName }
                        noBorders
                    />


                    {/* Alternative implementation */}
                    {/* const [ customerSuggestions, setCustomerSuggestions ] = useState(null)
                    const handleCustomerName = event => {
                        const c_name = event.target.value
                        setCustomerName(c_name)
                        setCustomerSuggestions(c_name?.trim().length ? customerList?.filter(item => item.toLowerCase().includes(c_name.trim().toLowerCase())) : "")
                    } */}
                    {/* 
                    <input 
                        type='text' 
                        className='w-100 border--light py-2 px-3 rounded' 
                        onChange={ handleCustomerName }
                        value={ customer_name }
                    />
                    <div className='position-relative'>
                        {
                            customerSuggestions?.length ? (
                                <div className='p-2 border--light rounded position-absolute w-100 bg-white customer-suggestions'>
                                    {
                                        customerSuggestions.map(item => (
                                            <span className='d-block pointer px-2 py-1 rounded' onClick={() => { setCustomerSuggestions([]); setCustomerName(item);}} >{ item }</span>
                                        ))
                                    }
                                </div>
                            ) : <></>
                        }
                    </div> */}

                </div>
                <div className="ml-4 w-50">
                    <span className="text-muted d-block mb-3">Date range</span>
                    <div className='d-flex'>
                        <div className='border--light rounded w-50'>
                            <DateRangesPicker setPayload={ setPayload } currentValue={ payload }/>
                        </div>
                        <button
                            type="submit"
                            className={ `btn px-3 btn-${ !disabled ? "primary": "disabled"} border-0 ml-4` } 
                            onClick={ apiCall }
                            disabled={ disabled }
                        >
                            <span>View report</span>
                        </button>
                    </div>
                </div>
            </div>

            {
                !isClicked 
                    ? <></>
                    : reports
                        ? <DataTable tableData={ { tableHead: customDatasetReportTableHead, tableBody: reports } } infoHead={ true } setMessageQueue={ setMessageQueue } />
                        : (
                            <div className="d-flex justify-content-center mt-5">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        ) 
            }  
        </div>
    )
}

export default CustomDatasetReport