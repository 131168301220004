
const helperTexts = {
    top1_avg: "Tells how relevant the 1st URL is to the keywords provided.",
    top10_avg: "Tells how relevant the top 10 URLs are to the keywords provided.",
    top50_avg: "Tells how relevant the top 50 URLs are to the keywords provided.",
    domains: ["You can add upto 50 domains.", "Valid http:// or https:// must be added before the domain."],
    name: "Should be 3-40 characters.",
    company_name: "Should be 3-20 characters."
}

export default helperTexts