import React from 'react'
import LogoutIcon from './assets/LogoutIcon.svg';
import { Link } from 'react-router-dom'

function LogoutSection(props) {
    const { collapse } = props

    return (
        <div className={ `${ collapse || "logout-area" } py-3` }>
            <Link to="/accounts/login">
                <div className={`logout bg--secondary py-2 pl-${ collapse ? '2' : '3' } mt-3 rounded`}>
                    <img src={ LogoutIcon } alt="logout" />
                    <span className={ `ml-2 ${collapse && "d-none" }` }>Logout</span>
                </div>
            </Link>
        </div>
    )
}

export default LogoutSection