import React, { useState } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import DomainSelector from './DomainSelector'
import { DataTable } from '../../../components/Table'
import { pathFor } from '../../../constants/apiPaths'
import Request from '../../../services/Request'
import { domainReportHeaders } from '../../../constants/tableConstants'


function DomainReport(props) {
    const { currentState, setCurrentState, setMessageQueue } = props
    const [ payload, setPayload ] = useState(currentState ? currentState.payload : null)
    const [ reports, setReports ] = useState(currentState ? currentState.reports : null)
    const [ isClicked, setIsClicked ] = useState(currentState ? currentState.isClicked : false)

    const apiCall = async () => {
        setIsClicked(true)
        setMessageQueue([])

        try {
            const { statusCode, jsonResponse } = await Request(pathFor.domainReport, payload)
            if (statusCode === 200) {
                const { data } = jsonResponse
                setReports(data)
                setCurrentState({
                    payload,
                    isClicked: true,
                    reports: data
                })
            } else if (statusCode === 401) {
                setIsClicked(false)
                setMessageQueue([jsonResponse])
            } else {
                setIsClicked(false)
                setMessageQueue([{"error": "Unable to fetch data."}])
            }
        } catch {
            setIsClicked(false)
            setMessageQueue([{"error": "Server error. Try again after sometime."}])
        }
    }
        

    return (
        <>
            <div className="mb-3 filter-container">
                <span className="text-muted d-block mb-3">Domain</span>
                <div className="d-flex">
                    <span className="mr-2 col-3 p-0 border--light rounded">
                        <DomainSelector setPayload={ setPayload } currentValue={ payload } setMessageQueue={ setMessageQueue } />
                    </span>
                    <button
                        type="submit"
                        className={ `btn px-3 btn-${ !!payload ? "primary": "disabled"} border-0` } 
                        onClick={ apiCall }
                        disabled={ !payload }
                    >
                        <span>View report</span>
                    </button>
                </div>
            </div>
            {
                !isClicked 
                    ? <></>
                    : reports
                        ? <DataTable tableData={ { tableHead: domainReportHeaders, tableBody: reports } } infoHead={ true } setMessageQueue={ setMessageQueue }/>
                        : (
                            <div className="d-flex justify-content-center mt-5">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        ) 
            }  
        </>
    )
}

export default DomainReport



