import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { DataTable } from 'components'
import { usersTableHead } from 'constants/tableConstants'
import { pathFor } from 'constants/apiPaths'
import Request from 'services/Request'
import { ReactComponent as SearchIcon } from 'assets/icons/misc/SearchIcon.svg'


function ClientManagement(props) {
    const { setMessageQueue } = props
    const history = useHistory()
    const [ tableBody, setTableBody ] = useState(null)
    const [ filterBy, setFilterBy ] = useState(null)
    const searchFields = ["name", "email", "compnay_name"]

    const filterClients = event => {
        event.preventDefault()
        setFilterBy({ key: event.target.filterValue.value.trim(), fields: searchFields })
    }
    
    // eslint-disable-next-line
    useEffect(async () => {
        try {
            const { statusCode, jsonResponse } = await Request(pathFor.clientManagement)
            if (statusCode === 200) {
                const { data } = jsonResponse
                setTableBody(data)
            } else setMessageQueue([jsonResponse])
        } catch {
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
    }, [])

    return (
        <div className="col-11 p-0">
            <div className="d-flex justify-content-between align-items-top mb-2">
                <p className="operation-table">CURRENT CLIENTS</p>
                <span className="d-flex">
                    <form className="search-filter border--light rounded d-flex" onSubmit={ filterClients }>
                        <input placeholder="Search" name="filterValue" className="border-0 outline-none rounded py-2 pl-3 flex-grow-1"/>
                        <button type="submit" className="border-0 bg--white p-0">
                            <SearchIcon className="my-2 mr-2 ml-1" />
                        </button>
                    </form>
                    <button type="button" className="btn bg--primary text--white px-3 ml-3" onClick={() => history.push(history.location.pathname + "/add")}>Add new client</button>
                </span>
            </div>
            <DataTable tableData={ { tableHead: usersTableHead("client"), tableBody } } setMessageQueue={ setMessageQueue } filterBy={ filterBy }/>
        </div>
    )
}

export default ClientManagement
