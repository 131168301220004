const pathFor = {
    usersStats: "/users-stats",
    datasetActivities: "/dataset-activities",
    advertiserDashboardStats: "/advertiser-dashboard-stats",
    ninaBotStats: "/nina-bot-stats",
    ninaBotPerformance: "/nina-bot-performance",
    insights: "/insights",
    allReports: "/all-reports",
    domainReport: "/domain-report",
    domainsList: "/domains-list",
    taxonomyReport: "/taxonomy-report",
    intentReport: "/intent-report",
    fetchCustomers: "/fetch-customers",
    customDatasetReport: "/custom-dataset-report",
    usersActivities: "/users-activities",
    inventoryDomains: "/inventory-domains",
    addDomains: "/add-domains",
    filterDomains: "/filter-domains",
    reCrawl: "/re-crawl",
    clientManagement: "/clients",
    addClient: "/add-client",
    editClient: "/edit-client",
    adminManagement: "/admins",
    addAdmin: "/add-admin",
    editAdmin: "/edit-admin",
    roles: "/roles",
    addRole: "/add-role",
    editRole: "/edit-role",
}

export { pathFor }
