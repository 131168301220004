import Papa from "papaparse";


const parseCsvfile = (file, callbackFn) => {
    const reader = new FileReader()

    reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, { header: true, skipEmptyLines: true})
        callbackFn(csv?.data)
    }

    reader.readAsText(file)
}


export { parseCsvfile }