import { mapLanguage } from 'utils/mappings'


const computeCount = (total, languageSelected) => {

    if (languageSelected) {
        const candidate = total.filter(item => item.lang === mapLanguage(languageSelected))[0]
        if (candidate) return candidate["count"]
        else return 0
    }
    return total ? total.map(item => item.count).reduce((acc, curr) => acc + curr) : 0
}

const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const generateExportData = (results, languageSelected, categorySelected) => {
    const data = [["Tier1", "Tier2", "No. of URLs"]]

    results.forEach(category => {
        if (categorySelected && category.taxonomy !== categorySelected) return

        data.push([category.taxonomy, "", computeCount(category.total, languageSelected)])

        if (!category.sub_categories) return

        category.sub_categories.forEach(sub_category => {
            data.push([
                category.taxonomy,
                sub_category.taxonomy,
                computeCount(sub_category.total, languageSelected)
            ])
        })

    })

    return data
}

export { computeCount, generateExportData, formatNumber }
