import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { pathFor } from '../../../constants/apiPaths'
import Request from '../../../services/Request'

function DomainSelector(props) {
    const { setPayload, currentValue, setMessageQueue } = props
    const [ selected, setSelected ] = useState(currentValue && "domain" in currentValue ? currentValue.domain : "Select domain")
    const [ domainsList, setDomainsList ] = useState([])
    const [ filterText, setFilterText ] = useState("")
    const filteredDomains = domainsList.filter(domain => domain.includes(filterText))

    // eslint-disable-next-line
    useEffect(async () => {
        try{
            const { statusCode, jsonResponse } = await Request(pathFor.domainsList)
            if (statusCode === 200) {
                const { data } = jsonResponse
                setDomainsList(data)
            } else if (statusCode === 401) {
                setMessageQueue([jsonResponse])
            } else {
                setMessageQueue([{"error": "Unable to fetch domains."}])
            }
        } catch(err) {
            setMessageQueue([{"error": "Server error. Try again after sometime."}])
        }
    }, [])


    return (
        <>
            <Dropdown onSelect={ value => {
                        setSelected(value)
                        setPayload({ domain: value })
                    }
                }
            >
                <Dropdown.Toggle variant="white" id="domain-selector" className="w-100 d-flex justify-content-between align-items-center">
                    <span className="overflow-hidden">
                        { selected }
                    </span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-100 bg--secondary border--light">
                    <input type="text" onChange={e => setFilterText(e.target.value)} className="mx-2 py-1 px-2 border--light domain-filter" placeholder="Type here to filter..." />
                    <div className="selector-menu w-100 mt-2">
                        {
                            filteredDomains.map((domain, idx) => <Dropdown.Item key={ idx } eventKey={ domain } className="dropdown-items px-3 py-1">{ domain }</Dropdown.Item>)
                        }
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default DomainSelector
