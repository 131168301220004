
const validateEmail = email => {

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}


const validateDomain = domain => {

    const re = /^https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/i
    return re.test(domain)
}

const validateDomains = domainsInput => {
    const domains = domainsInput.split('\n').map(domain => domain.trim()) 

    if (domains.length > 50) return {'error': "Number of domains must not exceed 50."}
    if (!domains.every(domain => validateDomain(domain))) return {'error': "Invalid domains received."}

    return domains
}

export { validateEmail, validateDomain, validateDomains }