const domainReportHeaders = [
    {
        title: "URL crawled",
        key: "url",
        sortable: true
    },
    {
        title: "Last crawled",
        key: "last_crawled_at",
        sortable: true
    },
    {
        title: "Compliant",
        key: "compliant",
        isBoolean: true,
        default: "No",
        sortable: true
    },
    {
        title: "Non-compliance reason",
        key: "non_compliance_reason",
        default: "NA",
        sortable: true
    },
    {
        title: "Page load speed",
        key: "page_load_speed",
        sortable: true,
        default: "0",
        suffix: "ms"
    },
    {
        title: "Page size",
        key: "page_size",
        isBytes: true,
        sortable: true,
        default: "0"
    }
]

const allReportsHeaders = [
    {
        title: "Domain",
        key: "domain",
        sortable: true
    },
    {
        title: "No. of URLs crawled",
        key: "page_count",
        sortable: true,
        isInt: true
    },
    {
        title: "No. of visits",
        key: "visit_count",
        sortable: true,
        isInt: true
    },
    {
        title: "Non-compliant URLs %",
        key: "non_compliance_percent",
        sortable: true,
        default: "0",
        suffix: "%"
    },
    {
        title: "Avg. page load speed",
        key: "avg_page_load_speed",
        sortable: true,
        default: "0",
        suffix: "ms"
    },
    {
        title: "Total volume",
        key: "total_page_size",
        isBytes: true,
        sortable: true,
        default: "0"
    }
];

const insightsTableHead = (versus) => (
    [
        {
            title: "Metric",
            key: "metric"
        }, {
            title: "Count",
            key: "count"
        }, {
            title: `Change % (vs ${versus})`,
            key: "change",
            suffix: "%",
            prefix: "+",
            colored: true
        }
    ]
)

const domainsTableHead = [
    {
        title: "Domain",
        key: "domain",
        checkBox: {
            checked: false
        }
    }, {
        title: "Added on",
        key: "date_added"
    }, {
        title: "Last scheduled",
        key: "last_scheduled"
    }
]

const usersTableHead = (userType) => (
    [
        {
            title: "Name",
            key: "name"
        }, {
            title: "Email",
            key: "email"
        }, {
            title: userType === "admin" ? "Role" : "Company",
            key: userType ==="admin" ? "role" : "company_name",
        }, {
            title: "User type",
            key: "organisation_type",
            hidden: userType === "admin",
            capitalise: true
        }, {
            title: "Status",
            key: "active",
            isStatus: true
        }, {
            actions: [
                {
                    text: "Edit",
                    key: "edit",
                    path: "/edit"
                }, {
                    text: `Resend ${ userType === "admin" ? "invitation" : "activation"} link`,
                    key: "resend",
                    dependentOn: "active",
                    noDisplayIf: [true, false],
                    path: `/resend-${ userType === "admin" ? "invitation" : "activation"}`
                }
            ]
        }
    ]
)

const rolesTableHead = [
    {
        title: "Role",
        key: "role_name"
    }, {
        actions: [
            {
                text: "Edit",
                key: "edit",
                path: "/edit"
            }
        ]
    }
]


const usersActivitiesHead = [
	{
		title: "Task",
		key: "task"
	},
	{
		title: "Status",
		key: "status"
	},
	{
		title: "Created by",
		key: "created_by"
	}
]


const inventoryDomainsTableHead = [
    {
        title: "Domain",
        key: "domain"
    }, {
        title: "Ad slots",
        key: "ad_slots"
    }, {
        title: "Geo",
        key: "geo"
    }, {
        title: "Traffic",
        key: "traffic",
        inThousands: true
    }, {
        title: "Floor CPM",
        key: "floorcpm"
    }, {
        title: "On demand",
        key: "ondemand",
        capitalise: true
    }
]


const customDatasetReportTableHead = [
    {
        title: "Date",
        key: "date"
    }, {
        title: "Taxonomies",
        key: "taxonomies",
        truncateAt: 50
    }, {
        title: "Keywords",
        key: "keywords",
        truncateAt: 25
    }, {
        title: "Geolocations",
        key: "geos",
        truncateAt: 25
    }, {
        title: "Languages",
        key: "languages",
        truncateAt: 25
    }, {
        title: "Emotions",
        key: "emotions"
    }, {
        title: "Intents",
        key: "intents",
        truncateAt: 25
    }, {
        title: "Data downloaded",
        key: "data_downloaded",
        isInt: true
    }, 
]


export { insightsTableHead, domainsTableHead, usersTableHead, rolesTableHead, allReportsHeaders, domainReportHeaders, usersActivitiesHead, inventoryDomainsTableHead, customDatasetReportTableHead }