import { displayText } from './generateTableCell'
import { parse } from "json2csv";

const generateExportData = (head, body) => {
    let data = []
    data.push(head.map(col => col.title))
    body.forEach(row => {
        data.push(
            head.map(col => displayText(row, col, true))
        )
    })

    return data
}

export default generateExportData