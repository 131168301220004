import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { DataTable } from 'components';
import { pathFor } from 'constants/apiPaths';
import { validateDomain } from 'utils/validations' 
import Request from 'services/Request';
import { FileUploader } from 'components';
import { inventoryDomainsTableHead } from 'constants/tableConstants';
import { parseCsvfile } from 'utils/parseFiles';
import { compareArrays, pluralise } from 'utils/common';


function AddNewDomains(props) {
    const { setMessageQueue } = props
    const history = useHistory()
    const [ uploadedFile, setUploadedFile ] = useState(null)
    const [ csvData, fetchCsvData ] =  useState(null)
    const [ tableBody, setTableBody ] = useState(null)

    const validateFile = file => file.type !== "text/csv" && {error: "Invalid file type. CSV required."}
    const fileUploadProps = { setUploadedFile, setMessageQueue, validateFile }

    useEffect(() => {
        setMessageQueue([])

        if (uploadedFile) {
            parseCsvfile(uploadedFile, fetchCsvData)
        }
    }, [uploadedFile])

    useEffect(() => {
        setTableBody(null)

        if (csvData) {
            if (compareArrays(Object.keys(csvData[0]), inventoryDomainsTableHead.map(i => i.key))) {
                const validData = csvData.filter(i => validateDomain(i.domain))
                const lengthDiff = csvData.length - validData.length
                setTableBody(validData)
                lengthDiff && setMessageQueue([{info: `${lengthDiff} ${pluralise("domain", lengthDiff)} rejected due to incorrect format.`}])
            } else {
                setMessageQueue([{error: "Incorrect data columns."}])
            }
        }
    }, [ csvData ])
    

    const handleSubmit = async () => {
        setMessageQueue([])

        try {
            const { statusCode, jsonResponse } = await Request(pathFor.addDomains, { data: tableBody })
            if (statusCode === 200) {
                history.push("/dashboard/operations/inventory-domains")
                setMessageQueue(jsonResponse.data)
            } else {
                setMessageQueue([jsonResponse])
            }
        } catch {
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
    }

    return (
        <>
            <div className="crumb bg--secondary w-100 py-2 px-3 rounded">
                <span className="mr-2">Inventory domains</span> 
                <p className="d-inline">
                    <FontAwesomeIcon icon={ faCaretRight } className="caret-right" />
                </p>
                <strong className="ml-2">Add new domains</strong>
            </div>
            <p className="operation-table mb-3 mt-4">ADD NEW DOMAINS</p>
            <FileUploader { ...fileUploadProps } btnLabel="Upload CSV" className="btn bg-secondary text--white px-3" accept=".csv" />

            {
                tableBody && (
                    <div className='col-10 p-0 pt-4'>
                        <DataTable tableData={{ tableHead: inventoryDomainsTableHead, tableBody }} />
                        <div className="d-flex justify-content-end mt-5">
                            <button type="button" className="btn bg-white px-3 py-2 btn-cancel text--primary" onClick={() => history.push("/dashboard/operations/inventory-domains")}>Cancel</button>
                            <button type="submit" className="btn bg--primary text--white py-2 px-3 ml-3" onClick={ handleSubmit }>Add domains</button>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default AddNewDomains