function sortByKeyAndOrder(key, order) {
    let sort_order = 1;
    if (order === "desc"){
        sort_order = -1;
    }
    return function (a, b){
        const first = a[key] || ""
        const second = b[key] || ""
        if (first < second){
                return -1 * sort_order;
        } else if (first > second) {
                return 1 * sort_order;
        } else {
                return 0;
        }
    }
}

const sortData = (data, sortProps) => {
    if (sortProps) {
        const { key, order } = sortProps
        return data.sort(sortByKeyAndOrder(key, order))
    } else {
        return data
    }
}

export default sortData