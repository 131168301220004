import React, { useRef, useState } from 'react'


function FileUploader(props) {
    const { setUploadedFile, btnLabel, className, validateFile, setMessageQueue, accept } = props
    const hiddenFileRef = useRef()
    const [ fileName, setFileName ] = useState("")
  
    const onChange = event => {
        setFileName("")
        setUploadedFile(null)
        const file = event.target.files[0]
        const fileValidationError = validateFile && validateFile(file)

        if (!fileValidationError) {
            setFileName(file.name)
            setUploadedFile(event.target.files[0])
        } else {
            setMessageQueue([fileValidationError])
        }
    }

    return (
        <div className='d-flex'>  
            <div className={ `d-${ fileName?.length ? "block" : "none" } filename-display py-2 px-3 border border-right-0 rounded-left` }>
                { fileName }
            </div>
            <button onClick={() => { setFileName(""); hiddenFileRef.current.click() }} className={ className }>
                { btnLabel }
            </button>
            <input type="file"
                ref={ hiddenFileRef }
                onChange={ onChange }
                className="d-none"
                accept={ accept }
            /> 
        </div>
    )
}

export default FileUploader