import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";


function DateSelector(props) {
  const { variant, setDateDelta } = props;
  const [ selected, setSelected ] = useState("Last 7 days");
  const bgVariant = variant || "white"

  return (
    <>
      <Dropdown
        onSelect={(val) => {
          setSelected(val !== "1" ? `Last ${val} days` : "Last day");
          setDateDelta(parseInt(val, 10));
        }}
      >
        <Dropdown.Toggle
          variant=""
          className={`date-selector py-2 px-3 text--${ variant === "primary" ? "white" : "body"} bg--${ bgVariant } border--light d-flex align-items-center justify-content-between`}
        >
          <div>{selected}</div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item eventKey="1" className="dropdown-items">
            Last day
          </Dropdown.Item>
          <Dropdown.Item eventKey="7" className="dropdown-items">
            Last 7 days
          </Dropdown.Item>
          <Dropdown.Item eventKey="14" className="dropdown-items">
            Last 14 days
          </Dropdown.Item>
          <Dropdown.Item eventKey="28" className="dropdown-items">
            Last 28 days
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default DateSelector;
