import dashboardPages from './dashboardPages'
import { AddNewAdmin, EditAdmin, AddNewClient, EditClient, AddNewRole, EditRole, AddNewDomains } from '../app/Dashboard/Operations'

const components = {
    inventoryDomains: {
        add: AddNewDomains
    },
    clientManagement: {
        add: AddNewClient,
        edit: EditClient
    },
    userManagement: {
        add: AddNewAdmin,
        edit: EditAdmin
    },
    roles: {
        add: AddNewRole,
        edit: EditRole
    }
}

const operations = dashboardPages.filter(item => item.key === "operations")[0]

let operationsConstants = []
operations.subItems.forEach(item => {
    operationsConstants.push({
        path: operations.path + item.path + "/add",
        text: item.text,
        component: components[item.key].add
    })
    operationsConstants.push({
        path: operations.path + item.path + "/edit",
        text: item.text,
        component: components[item.key].edit
    })
})


export default operationsConstants