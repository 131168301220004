import React, { useState } from 'react';
import Logo from 'assets/images/logo/Logo.svg';
import LogoText from 'assets/images/logo/LogoText.svg';
import { MessageQueue } from 'components'

function Page(props) {
    const { component: Component } = props
    const [ messageQueue, setMessageQueue ] = useState([])
    
    return (
        <div className="page d-flex justify-content-center align-items-center">
            <div className="auth-card">
                <div className="d-flex justify-content-center pb-3">
                    <img src={ Logo } alt="logo" />
                    <img src={ LogoText } alt="logo-text" className="ml-1 mt-1"/>
                </div>
                <Component setMessageQueue={ setMessageQueue }/>
            </div>
            <MessageQueue messageQueue={ messageQueue } />
        </div>
    )
}

export default Page
