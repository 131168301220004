import React from 'react'
import { Redirect, Route } from 'react-router-dom'

function PrivateRoute(props) {
    const { ...rest } = props
    const ninaToken = localStorage.getItem("ninaToken")
    
    return (
        <div>
            {
                ninaToken ? (
                    <Route { ...rest } >
                        {props.children}
                    </Route>
                ) : (
                    <Redirect to="/accounts/login" />
                )
            }
        </div>
    )
}

export default PrivateRoute
