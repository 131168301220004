import React from 'react'
import Check from 'assets/icons/misc/Check.png'
import Minus from 'assets/icons/misc/Minus.png'

function CheckBoxes(props) {
    const { permissions, setPermissions, checkBoxes } = props
    
    const subs = []
    checkBoxes.forEach(box => box.parent && subs.push(box.text))
    let someSub = subs.some(sub => permissions[sub])
    let allSub = subs.every(sub => permissions[sub])
    if (subs.every(sub => !permissions[sub])) permissions['Operations'] = false
    if (someSub) permissions['Operations'] = true

    const handleClick = item => {
        let temp = { ...permissions }
        if (item.hasSubmenu) checkBoxes.forEach(box => box.parent === item.text && (temp[box.text] = !temp[item.text]))
        temp[item.text] = !temp[item.text]
        setPermissions(temp)
    }

    return (
        <>
            {
                checkBoxes.map(box => (
                    <div className={ `d-flex align-items-center ${box.parent && "ml-4"}` }>
                        <span onClick={() => handleClick(box)} className="checkbox">
                            {
                                permissions[box.text] && (
                                    <img src={ !box.hasSubmenu ? Check : allSub ? Check : Minus} alt="selected" width="15" className="check-icon"/>
                                )
                            }
                        </span>
                        { box.text }
                    </div>
                ))
                
            }
        </>
    )
}

export default CheckBoxes
