import React, { useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

function DateRangesPicker(props) {
    const {setPayload, currentValue} = props
    const [btnText, setBtnText] = useState(currentValue && "date-start" in currentValue
                                                ? `${currentValue['date-start']} to ${currentValue['date-end']}`
                                                : "Select date range"
                                            )
    
    const handleRange = (start, end) => {
        const dateStart = start.add(1, 'days').toISOString().split('T')[0]
        const dateEnd = end.toISOString().split('T')[0]
        const payload = {
            'date-start': dateStart,
            'date-end': dateEnd,
        }
        setBtnText(`${dateStart} to ${dateEnd}`)
        setPayload(payload)
    }

    return (
        <>
            <DateRangePicker
                onCallback={ handleRange }
                initialSettings={{ 
                    alwaysShowCalendars: true,
                    ranges: {
                       'Last day': [moment().subtract(1, 'days'), moment()],
                       'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                       'Last 14 Days': [moment().subtract(13, 'days'), moment()],
                       'Last 28 Days': [moment().subtract(27, 'days'), moment()],
                       'MTD': [moment().startOf('month'), moment()],
                    }
                }}
            >
                <button type="button" className="btn btn-white w-100">
                    <div className="d-flex justify-content-between align-items-center">
                        { btnText }
                        <FontAwesomeIcon icon={ faCaretDown } size="sm" />
                    </div>
                </button>
            </DateRangePicker>
        </>
    )
}

export default DateRangesPicker
