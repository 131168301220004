import React, { useEffect, useState } from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "components/Table";
import DateSelector from "components/DateSelector";
import { PieChart } from "components/Charts";
import { insightsTableHead } from "constants/tableConstants";
import getDates from 'utils/getDates'
import InsightsAPI from 'services/InsightsAPI';


function Insights(props) {
	const { setMessageQueue } = props
	const [ dateDelta, setDateDelta ] = useState(7)
	const [ responseData, setResponseData ] = useState({insightsTable: [], nonComplianceReasons: [], pageSpeeds: []})
    const versus = dateDelta === 1 ? "last day" : `last ${dateDelta} days`

	// eslint-disable-next-line
	useEffect(async () => {
        const payload = getDates(dateDelta)
		try {
			const response = await InsightsAPI(payload)
			setResponseData(response)
		} catch(err) {
			setMessageQueue([{error: "Server error. Try again after sometime."}])
		}
    }, [dateDelta])

	return (
		<div className="bg-light p-4">
			<div className="d-flex justify-content-between mb-3">
				<p className="text--secondary">INSIGHTS</p>
				<DateSelector setDateDelta={ setDateDelta } />
			</div>
			<Row>
				<Col sm={8}>
					<div className="bg-white widgets">
						<Table 
							className="table table-borderless table-striped"
							tableData={{ 
								tableHeadClassName: "text--secondary", 
								tableHead: insightsTableHead(versus), 
								tableBody: responseData.insightsTable 
							}} 
						/>
					</div>
					<div className="bg-white widgets mt-4 p-4">
						<PieChart
							chartData={ responseData.nonComplianceReasons }
							title="NON-COMPLIANCE REASONS"
							legendDirection="right"
							dimensions={{ height: "350", width: "400" }}
							labelOffset={ 80 }
							layout={{
								padding: {
									right: 100
								}
							}}
						/>
					</div>
				</Col>
				<Col sm={4}>
					<div className="bg-white widgets p-4">
						<PieChart
							chartData={ responseData.pageSpeeds }
							title="PAGE SPEED"
							legendDirection="bottom"
							legendAlignment="start"
							dimensions={{ height: "400", width: "400" }}
						/>
					</div>
				</Col>
			</Row>
		</div>
    )
}

export default Insights