
const calculate = (value, denominations, divisor) => {
    
    if (isNaN(value) || ["", null, undefined].includes(value) || typeof value === "boolean" || value instanceof Array) return "Invalid"
    const _value = parseFloat(value)
    if (_value < 0) return "Negative"

    const i = Math.floor(Math.log(_value) / Math.log(divisor))

    return _value === 0 ? `0 ${denominations[0]}` : `${parseFloat((_value / (divisor ** i)).toFixed(2))} ${denominations[i]}`
}


const calculateBytes = (value) => {

    const denominations = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    return calculate(value, denominations, 1024) 
}


const calculateThousands = (value) => {
    
    const denominations = ['', 'k', 'm', 'b', 't', 'q'];
    value = `${value}`.trim()
    const lastIndex = value.length - 1
    
    return denominations.slice(1).some(item => value.toLowerCase().endsWith(item))
        ? `${value.slice(0, lastIndex).trim()} ${value[lastIndex].toUpperCase()}`
        : calculate(value, denominations.map(item => item.toUpperCase()), 1000) 
}

export { calculateBytes, calculateThousands }