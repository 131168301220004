import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { CheckBoxes, Helper } from 'components';
import { pathFor } from 'constants/apiPaths';
import dashboardPages from 'constants/dashboardPages'
import Request from 'services/Request';

function EditRole(props) {
    const { setMessageQueue } = props
    const history = useHistory()
    const id = history.location.pathname.split("/").pop()
    const [ readOnly, setReadOnly ] = useState(false)
    const [ roleName, setRoleName ] = useState("")
    const [ roleNameError, setRoleNameError ] = useState(null)
    const checkBoxes = []

    dashboardPages.forEach(item => {
        if (item.subItems) {
            checkBoxes.push({text: item.text, hasSubmenu: true})
            item.subItems.forEach(subItem => {
                checkBoxes.push({text: subItem.text, parent: item.text})
            })
        } else {
            checkBoxes.push({text: item.text})
        }
    })

    const [ permissions, setPermissions ] = useState(Object.fromEntries(checkBoxes.map(box => ([box.text, false]))))
    const checkBoxesProps = { permissions, setPermissions, checkBoxes }
    const [ junk, setJunk ] = useState("")
    const [ disabled, setDisabled ] = useState(true)

    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessageQueue([])
        let noErrors = true
        
        if (readOnly && junk) {
            setMessageQueue([{error: "The role name cannot be changed."}])
            return false
        }
        if (roleName.length < 3 || roleName.length > 40) { noErrors = false; setRoleNameError("Length must be between 3 and 40"); }

        if (noErrors) {
            try {
                const { statusCode, jsonResponse } = await Request(`${pathFor.editRole}/${id}`, { role_name: roleName, 'components_access': Object.keys(permissions).filter(k => permissions[k]) })
                setMessageQueue([jsonResponse])
                if (statusCode === 200) history.push("/dashboard/operations/roles")
                else setMessageQueue([jsonResponse])
            } catch {
                setMessageQueue([{error: "Server error. Try again after sometime."}])
            }
        }
    }
   
    useEffect(() => setDisabled(Object.values(permissions).every(v => !v)), [permissions])

    // eslint-disable-next-line
    useEffect(async () => {
        try {
            const { statusCode, jsonResponse } = await Request(`/role/${id}`)
            if (statusCode === 200) {
                const data = jsonResponse.data[0]
                setRoleName(data['role_name'])
                setReadOnly(true)
                const components_access = data['components_access']
                const temp = { ...permissions }
                components_access.forEach(comp => comp in temp && (temp[comp] = true))
                setPermissions(temp)
            } else {
                setMessageQueue([jsonResponse])
            }
        } catch(err) {
            setMessageQueue([{error: err.message + " role details."}])
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="crumb bg--secondary w-100 py-2 px-3 rounded">
                <span className="mr-2">Roles</span> 
                <p className="d-inline">
                    <FontAwesomeIcon icon={ faCaretRight } className="caret-right" />
                </p>
                <strong className="ml-2">Edit role</strong>
            </div>
            <p className="operation-table my-3">EDIT ROLE</p>
            <form className="col-xl-4 col-lg-5 col-md-6 col-sm-7 col-8 py-1 px-0" onSubmit={ handleSubmit }>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex"> 
                            <label htmlFor="role-name">
                                <span className="text--secondary">
                                    Role name
                                </span>
                            </label>
                            <Helper itemKey="name" variant="gray" />
                        </div>
                        <span className="text-danger">
                            <i>{ roleNameError }</i>
                        </span>
                    </div>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="role-name" 
                        placeholder="Enter role name" 
                        readOnly={ readOnly } 
                        onChange={e => readOnly ? setJunk(e.target.value.trim()) : setRoleName(e.target.value.trim())} 
                        value={ roleName }    
                    />
                </div>
                <span className="text--secondary">
                    <label>
                        Components access
                    </label>
                </span>
                <div className="mt-2 ml-1">
                    <CheckBoxes { ...checkBoxesProps } />
                </div>
                <div className="d-flex justify-content-end mt-4">
                    <button type="button" className="btn bg-white px-3 py-2 btn-cancel text--primary" onClick={() => history.push("/dashboard/operations/roles")}>Cancel</button>
                    <button type="submit" className="btn bg--primary text--white py-2 px-3 ml-3" disabled={ disabled }>Make changes</button>
                </div>
            </form>
        </>
    )
}

export default EditRole
