const languageMappings = {
    "en": "english",
    "es": "spanish",
    "fi": "finnish",
    "sv": "swedish",
    "no": "norwegian",
    "da": "danish"
}

const intentMappings = {
    "informational": "Informational",
    "transactional": "Transactional",
    "navigational": "Navigational",
    "navigational_informational": "Navigational informational",
    "navigational_transactional": "Navigational transactional",
    "transactional_download": "Transactional download"
}

const customerIntentMappings = {
    "Branding Awareness": ["informational", "navigational", "navigational_informational"],
    "Performance Consideration": ["navigational", "navigational_informational", "navigational_transactional"],
    "Performance Acquisition": ["transactional", "transactional_download"]
}

export { languageMappings, intentMappings, customerIntentMappings }
