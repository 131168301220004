import React from 'react';
import Page from './Page';
import pageRoutes from './constants/pageRoutes';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';


function Routes() {
    const match = useRouteMatch()

    return (
        <Switch>
            {
                pageRoutes.map(item => (
                    <Route path={`${match.path}${item.path}`}>
                        <Page component={ item.component } />
                    </Route>
                ))
            }
            <Redirect to={ `${match.path}${pageRoutes[0].path}`} />
        </Switch>
    )
}

export default Routes;