import React from 'react'
import Snackbar from './Snackbar'

function MessageQueue({ messageQueue }) {
    return (
        <div className={ messageQueue && messageQueue.length ? "message-queue m-4" : "d-none" }>
            {
                messageQueue.map(msg => (
                    <Snackbar msg={ msg } />
                ))
            }
        </div>
    )
}

export default MessageQueue
