import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'


function DropDown(props) {
    const { options, setSelected, defaultValue, className, selected, btnClassName, menuClassName, title, noBorders } = props
    const [ showMenu, setShowMenu ] = useState(false)
    const [ displayText, setDisplayText ] = useState(selected || "")


    return (
        <div className={ className } onClick={() => setShowMenu(!showMenu)}>
            <button className={ `btn btn-white ${ btnClassName || "drop-down" } d-flex justify-content-between mt-1 pt-2` }>
                <span className="text-truncate">{ displayText.trim() || title || defaultValue }</span>
                <FontAwesomeIcon icon={ faCaretDown } className="m-1" />
            </button> 
            <div className={ `d-${ showMenu ? "block" : "none"} ${ menuClassName || "selector-menu" } p-2 rounded` }>
                {
                    defaultValue && (displayText != defaultValue) && (
                        <div className="drop-down-item clickable border-bottom" onClick={() => {
                            setDisplayText(defaultValue);
                            setSelected(null); 
                        }}>{ defaultValue }</div>
                        )
                    }
                {
                    options.map(item => {
                        let displayValue, value;
                        displayValue = value = item;
                        
                        if (displayText === displayValue) {
                            return
                        }
                        
                        if (item instanceof Object) {
                            value = Object.keys(item)[0]
                            displayValue = Object.values(item)[0]
                        } 
                        
                        return (
                            <div className={ `drop-down-item clickable border-${noBorders ? "0" : "bottom"} rounded` } onClick={() => {
                                setDisplayText(displayValue);
                                setSelected(value); 
                            }}>{ displayValue }</div>
                            )
                        })
                    }
            </div>
        </div>
    )
}

export default DropDown
