import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Request from 'services/Request'
import { validateEmail } from 'utils/validations'

function Login(props) {
    const { setMessageQueue } = props
    const [ email, setEmail] = useState("")
    const [ password, setPassword ] = useState("")
    const [ emailError, setEmailError ] = useState("")
    const [ passwordError, setPasswordError ] = useState("")
    const ninaToken = localStorage.getItem("ninaToken")
    const disabled = !email || !password || !!passwordError || !!emailError

    if (disabled && ninaToken) {
        Request("/revoke-token")
        localStorage.clear()
    }
    
    const handleSubmit = async (event) => {
        event.preventDefault()
        setMessageQueue([])
        
        if (!validateEmail(email)) {
            setMessageQueue([{'error': "Invalid email"}])
            return false
        }

        try {
            const { statusCode, jsonResponse } = await Request("/login", { email, password })
            if (statusCode === 200) window.location.replace("/")
            if (statusCode === 400) {
                const { errors } = jsonResponse
                if ("json" in errors) {
                    const { email } = errors.json
                    email ? setEmailError("Invalid email.") : setMessageQueue([{error: "Invalid input."}])
                } else {
                    setMessageQueue([{error: "Something went wrong."}])
                }
            }
            else setMessageQueue([jsonResponse])
        } catch {
            setMessageQueue([{error: "Server error. Try again after sometime."}])
        }
    }

    useEffect(() => {
        setEmailError("")
        setPasswordError("")
        setMessageQueue([])
        // eslint-disable-next-line
    }, [ password, email ])

    return (
        <>
            <form className="mt-4" onSubmit={ handleSubmit }>
                <div className="form-group">
                    <label htmlFor="email" className="label d-flex justify-content-between">
                        <strong>Email</strong>   
                    </label>
                    <input 
                        type="email" 
                        className="form-control" 
                        id="email" 
                        placeholder="Enter email"
                        onChange={e =>  setEmail(e.target.value)} 
                    />
                    <span className="text-danger font-weight-bold">
                        <i>{ emailError }</i>
                    </span>
                </div>
                <div class="form-group">
                    <label htmlFor="password" className="label d-flex justify-content-between">
                        <strong>Password</strong>   
                    </label>                        
                    <input
                        type="password" 
                        className="form-control" 
                        id="password" 
                        placeholder="Enter password"    
                        onChange={e => setPassword(e.target.value)} 
                    />
                    <span className="text-danger font-weight-bold">
                        <i>{ passwordError }</i>
                    </span>
                </div>
                <a href="/accounts/reset-password">Forgot password?</a>
                <div className="d-flex justify-content-center mt-4 pt-2">
                    <button type="submit" className="btn btn-primary py-2 px-3" disabled={ disabled }>Login</button>
                </div>
            </form>
        </>
    )
}

export default Login
